export const GET_PINS = "GET_PINS";
export const GET_PIN = "GET_PIN";
export const GET_PIN_ERROR = "GET_PIN_ERROR";
export const PIN_LOADING = "PIN_LOADING";
export const DELETE_PINS = "DELETE_PINS";
export const ADD_PIN = "ADD_PIN";
export const USER_LOADING = "USER_LOADING";
export const EDIT_PIN = "EDIT_PIN";
export const GET_PIN_BY_ID = "GET_PIN_BY_ID";
export const GET_ERRORS = "GET_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const GET_USERS = "GET_USERS";
export const DELETE_USER = "DELETE_USER";
export const GET_USER = "GET_USER";
export const SEARCH_PINS = "SEARCH_PINS";
export const GET_UPVOTE = "GET_UPVOTE";
export const GET_FLAG_STATE = "GET_FLAG_STATE";
export const ADD_COMMENT = "ADD_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const GET_PINS_BY_OWNER = "GET_PINS_BY_OWNER";
export const EDIT_USER = "EDIT_USER";
export const UPDATE_ABOUT_US = "UPDATE_ABOUT_US";
export const GET_ABOUT_US = "GET_ABOUT_US";
export const USER_FLAG_PIN = "USER_FLAG_PIN";
export const USER_FIRST_UPVOTE = "USER_FIRST_UPVOTE";
export const USER_UPVOTE = "USER_UPVOTE";
export const EDIT_USER_ROLE = "EDIT_USER_ROLE";
export const SEARCH_USERS = "SEARCH_USERS";
export const USER_UNFLAG = "USER_UNFLAG";
export const GET_FLAGGED_PINS = "GET_FLAGGED_PINS";
export const GET_NEXT_FLAGGED_PINS = "GET_NEXT_FLAGGED_PINS";
export const GET_NEXT_PREVIOUS_USERS = "GET_NEXT_PREVIOUS_USERS";
export const USER_SELF_DELETE = "USER_SELF_DELETE";
export const FLAG_COMMENT = "FLAG_COMMENT";
export const REMOVE_FLAG_COMMENT = "REMOVE_FLAG_COMMENT";
export const GET_MAX_PIN = "GET_MAX_PIN";
export const GET_MIN_PIN = "GET_MIN_PIN";
export const GET_USER_FAVORITE_STORIES = "GET_USER_FAVORITE_STORIES";
export const UPDATE_PROFILE_PIC = "UPDATE_PROFILE_PIC";
export const EDIT_PIN_PRO = "EDIT_PIN_PRO";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_PROFILE_NOT_FOUND = "USER_PROFILE_NOT_FOUND";
export const USER_FAVORITE_STATE_PIN = "USER_FAVORITE_STATE_PIN";
export const GUEST_USER = "GUEST_USER";
export const UNFAVORITE_PROFILE_STORY = "UNFAVORITE_PROFILE_STORY";
export const ADMIN_DELETE_FLAGGED_PIN = "ADMIN_DELETE_FLAGGED_PIN";
